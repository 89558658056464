import React, { useState, createContext, useContext, useEffect } from 'react';

const Context = createContext([]);

export default function Cart({ children }) {
	const [state, setState] = useState([]);
	const [message, setMessage] = useState(null);
	const [value, setValue] = useState([state, setState, message, setMessage]);
	useEffect(() => {
		setValue([state, setState, message, setMessage]);
	}, [state, message]);
	return <Context.Provider value={value} children={children} />;
}

export function useCart() {
	return useContext(Context);
}
