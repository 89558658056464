import React from 'react';
import Container from 'react-bootstrap/Container';
import BreadCrumbs from './BreadCrumbs';

export default function PageContainer({ className, children, container, fluid, wrapperClassName, crumbs = true, dark = true }) {
	const classes = ['basic-page'];
	if(container) {
		classes.push(`${container}-container`);
	}
	if(className) {
		classes.push(className);
	}
	const wrapperClasses = ['page-container-wrapper', wrapperClassName].filter(Boolean);
	if(dark) {
		wrapperClasses.push('dark');
	}
	return <div className={wrapperClasses.join(' ')}>
		<Container className={classes.join(' ')} fluid={fluid}>
			{crumbs && <BreadCrumbs />}
			{children}
		</Container>
	</div>;
}
