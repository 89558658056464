import React from 'react';
import BAlert from 'react-bootstrap/Alert';
import HttpStatus from 'http-status-codes';

export default function Error({ error, color = 'danger', ...props }) {
	return <BAlert variant={color} {...props}>{error}</BAlert>;
}

export function getMessage(error) {
	let { message } = error;
	if(error.response?.data?.message) {
		message = error.response.data.message;
		if(error.response.status === HttpStatus.BAD_REQUEST && error.response.data.invalid?.length) {
			message = <>
				{message}<br />
				{error.response.data.invalid.join(', ')}
			</>;
		}
	}
	return message;
}

export function Alert({ error, ...props }) {
	return <Error {...props} error={getMessage(error)} />;
}

export function Loading({ error }) {
	if(error) {
		return <Alert error={error} />;
	}
	return <p>Loading...</p>;
}
