import React, { useEffect, useState } from 'react';
import { getMe } from '../api';
import Login from '../components/auth/Login';
import Routes from './Routes';
import LazyLoader from '../components/LazyLoader';
import 'react-datepicker/dist/react-datepicker.css';

export default function App() {
	const [user, setUser] = useState(null);
	const [error, setError] = useState(null);
	useEffect(() => {
		getMe().then(setUser).catch(setError);
	}, []);
	if(!user) {
		return <Login error={error} />;
	}
	return <LazyLoader>
		<Routes user={user} />
	</LazyLoader>;
}
