import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ItemsLink } from './items/Filters';

const CrumbsContext = React.createContext(null);
const SPECIAL_ROUTES = ['edit', 'view', 'form', 'all', 'clone', 'vendor'];

function Header({ children, sep, muted = false }) {
	const classes = ['m-0'];
	if(muted) {
		classes.push('text-muted');
	}
	return <h6 className={classes.join(' ')}>
		{sep && <>&nbsp;/&nbsp;</>}
		{children}
	</h6>;
}

function CrumbLink({ text, to }) {
	let Comp = Link;
	if(to === '/items') {
		Comp = ItemsLink;
	}
	return <Header sep={to !== '/'}>
		<Comp className="crumb" to={to}>{text}</Comp>
	</Header>;
}

function getDefaultBreadCrumbs(pathname) {
	const crumbs = pathname.split('/');
	return crumbs.map((c, index) => {
		if(SPECIAL_ROUTES.includes(crumbs[index - 1])) return null;
		if(index === crumbs.length - 1 || SPECIAL_ROUTES.includes(c)){
			return <Header key={index} sep muted>{c}</Header>;
		}
		const path = crumbs.reduce((p, f, i) => {
			if(!i || i > index) {
				return p;
			}
			return p + '/' + f;
		}, '') || '/';
		return <CrumbLink key={index} text={c === '' ? 'home' : c} to={path} />;
	});
}

function getBreadCrumbsOverride(crumbs) {
	return crumbs.map((crumb, i) => {
		return <Header key={i} sep={i > 0} muted={i === crumbs.length - 1}>{crumb}</Header>;
	});
}

export default function BreadCrumbs({ className, theme = 'light' }) {
	const { pathname } = useLocation();
	const [manualCrumbs] = useContext(CrumbsContext);
	let children;
	if(pathname in manualCrumbs) {
		children = getBreadCrumbsOverride(manualCrumbs[pathname]);
	} else if(pathname === '/') {
		return null;
	} else {
		children = getDefaultBreadCrumbs(pathname);
	}
	const classes = [`d-flex flex-row justify-content-center pb-3 text-${theme} unselectable font-italic`, className].filter(Boolean);
	return <div className={classes.join(' ')} children={children} />;
}

export function CrumbsProvider({ children }) {
	const state = useState({});
	return <CrumbsContext.Provider children={children} value={state} />;
}

export function useCrumbs(crumbs) {
	const { pathname } = useLocation();
	const setState = useContext(CrumbsContext)[1];
	useEffect(() => {
		if(crumbs?.length) {
			setState(prev => ({ ...prev, [pathname]: crumbs }));
		}
		return () => setState(prev => {
			if(pathname in prev) {
				const copy = { ...prev };
				delete copy[pathname];
				return copy;
			}
			return prev;
		});
	}, [pathname, crumbs]);
}
