import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { getItemsInSequence } from '../../api';

const MAX_ITEMS = 100;
const EMPTY = {
	filters: {},
	items: [],
	itemsBefore: false,
	itemsAfter: false,
	item: null
};

const Context = createContext(EMPTY);

function getSetFilters(setValue) {
	return filters => {
		setValue(v => {
			if(queryString.stringify(v.filters) === queryString.stringify(filters)) {
				return v;
			}
			return { ...v, filters };
		});
	};
}

function getSetItems(setValue) {
	return response => {
		const { total, items } = response;
		if('limit' in response) {
			const { direction } = response;
			setValue(v => {
				const copy = { ...v };
				if(direction > 0) {
					copy.itemsAfter = items.length < total;
					copy.items = copy.items.concat(items);
					if(copy.items.length > MAX_ITEMS) {
						copy.items = copy.items.slice(copy.items.length - MAX_ITEMS);
						copy.itemsBefore = true;
					}
				} else {
					copy.itemsBefore = items.length < total;
					copy.items = items.concat(copy.items);
					if(copy.items.length > MAX_ITEMS) {
						copy.items = copy.items.slice(0, MAX_ITEMS);
						copy.itemsAfter = true;
					}
				}
				return copy;
			});
		} else {
			const { page, perPage } = response;
			setValue(v => {
				return { ...v, items: items.map(i => i._id), itemsBefore: page > 1, itemsAfter: page * perPage < total };
			});
		}
	};
}

export default function ItemFilters({ children }) {
	const [value, setValue] = useState(EMPTY);
	Object.assign(value, useMemo(() => {
		return {
			setFilters: getSetFilters(setValue),
			setItems: getSetItems(setValue),
			setItem(item) {
				setValue(prev => ({ ...prev, item }));
			}
		};
	}, [setValue]));
	return <Context.Provider value={value} children={children} />;
}

export function useItemFilters() {
	return useContext(Context);
}

export function getItemsLink(filters, item) {
	const copy = { ...filters };
	for(const key in copy) {
		if(!copy[key] && copy[key] !== 0) {
			delete copy[key];
		}
	}
	let to = '/items';
	const query = queryString.stringify(copy);
	if(query) {
		to += '?' + query;
	}
	if(item) {
		to += '#' + item;
	}
	return to;
}

export function ItemsLink(props) {
	const { filters, item } = useItemFilters();
	const to = getItemsLink(filters, item);
	return <Link {...props} to={to} />;
}

function ItemLink({ item, direction, all, ...props }) {
	const { itemsAfter, itemsBefore, items, filters, setItems } = useItemFilters();
	const [id, setId] = useState(null);
	useEffect(() => {
		const current = items.indexOf(item);
		const next = current + direction;
		if(current >= 0) {
			if(!itemsBefore && next < 0 || !itemsAfter && next >= items.length) {
				return setId(null);
			} else if(next >= 0 && next < items.length) {
				return setId(items[next]);
			}
		} else {
			return setId(null);
		}
		const fetch = { active: true };
		if(id) {
			setId(null);
		}
		getItemsInSequence(item, direction, filters).then(result => {
			if(fetch.active) {
				setItems(result);
			}
		}).catch(err => {
			console.error('Failed to determine next/previous item', err);
		});
		return () => {
			fetch.active = false;
		};
	}, [item, direction, items, itemsBefore, itemsAfter]);
	if(!id) {
		return null;
	}
	return <Link {...props} to={`/items/form${all ? '/all' : ''}/${encodeURIComponent(id)}`} />;
}

export function NextItem(props) {
	return <ItemLink {...props} direction={1} />;
}

export function PreviousItem(props) {
	return <ItemLink {...props} direction={-1} />;
}
