import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './containers/App';
import Cart from './containers/cart/Cart';
import ItemFilters from './containers/items/Filters';
import { CrumbsProvider } from './containers/BreadCrumbs';
import history from './history';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.scss';

ReactDOM.render(
	<Router history={history}>
		<React.StrictMode>
			<CrumbsProvider>
				<Cart>
					<ItemFilters>
						<App />
					</ItemFilters>
				</Cart>
			</CrumbsProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root')
);
