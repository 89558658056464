import React from 'react';
import { Alert } from '../Error';
import PageContainer from '../../containers/PageContainer';

export default function Login({ error }) {
	if(error) {
		if(error.loginUrl) {
			return <PageContainer className="vh-100 d-flex justify-content-center align-items-center flex-column">
				<h1>Welcome to the PTMD PIM website</h1>
				<a className="btn btn-primary" href={error.loginUrl}>Login</a>
			</PageContainer>;
		}
		return <Alert error={error} />;
	}
	return <p>Loading...</p>;
}
