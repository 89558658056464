import React, { Component, Suspense } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import axios from 'axios';

const LAST_MODIFIED = new Date(document.lastModified);

function Loader() {
	return <p>Loading...</p>;
}

export default class LazyLoader extends Component {
	static getDerivedStateFromError(error) {
		if(error.name === 'ChunkLoadError') {
			axios.get('/').then(res => {
				const current = new Date(res.headers['last-modified']);
				if(current > LAST_MODIFIED || isNaN(current)) {
					window.location.reload();
				}
			}).catch(err => {
				console.warn('Failed to load html', err);
			});
			return { error: <>
				Failed to load PIM. This may indicate a problem with your connection.<br />
				<small>{error.message}</small>
			</> };
		}
		return { error: error.message };
	}

	render() {
		if(this.state?.error) {
			return <Container>
				<h1>An error occurred while loading the page</h1>
				<Alert variant="danger">{this.state.error}</Alert>
			</Container>;
		}
		return <Suspense fallback={<Loader />}>
			{this.props.children}
		</Suspense>;
	}
}
